import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby"

const Nav = ({ onMenuToggle = () => {} }) => {

  const { contentfulMenu } = useStaticQuery(
    graphql`
      query MenuQuery {
        contentfulMenu {
          name
          links {
            ... on ContentfulPage {
              id
              name
              url
            }
          }
        }
      }
    `
  )

  const menuItems = contentfulMenu.links.map(({name, url}) => { return { url, name}  })

  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>{contentfulMenu.name}</span>
          </a>
          <div id="menu">
            <ul>
              { 
              menuItems.map(item => <li key={item.name}>
                <Link to={item.url}>{item.name}</Link>
              </li>)
              }
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}


export default Nav