import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'





export default function Footer() {

  const {allContentfulFooter} = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter(filter: {id: {eq: "f40c2981-e19e-543f-a36f-8a08be724f36"}}) {
        nodes {
          copyright
          links {
            iconCode
            text
            linkTo
            style
          }
          logo {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)

  const footer = allContentfulFooter.nodes[0];

  return (
    <footer id="footer">
      <ul className="icons">
        {footer.links.map(social => {
          const { style, iconCode, text, linkTo } = social;
          return (
            <li key={text}>
              <a href={linkTo} className={`icon ${style} ${iconCode}`}>
                <span className="label">{text}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <img style={{width: 200}} src={footer.logo.file.url} alt={footer.logo.title}/>
      <ul className="copyright">
      <li> {footer.copyright} { new Date(Date.now()).getFullYear()}</li>
      </ul>
    </footer>
  );
}
